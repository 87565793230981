import React, { useState, useEffect } from "react"
import Chat, { Message } from "../templates/Chat/Chat"
import axios from "axios"
import {
  CollectInfoAPIType,
  PropertyType,
  BasicInfoType,
  AbstractInfoType,
  SuggestPropertyAPIType,
} from "../type/type_v11"
import {
  START_CONVERSATION_API_URL,
  SUGGEST_PROPERTY_API_URL,
  COLLECT_ABSTRACT_INFO_API_URL,
  COLLECT_BASIC_INFO_API_URL,
  BASIC_CONVERSATION_NUM,
  ABSTRACT_CONVERSATION_NUM,
} from "../constant/constant"
import Header from "../components/Header"

const AIChatPageView: React.FC = () => {
  const [inputMessage, setInputMessage] = useState<string>("")
  const [chatHistory, setChatHistory] = useState<Message[]>([])
  const [propertyList, setPropertyList] = useState<PropertyType[] | undefined>(undefined)
  const [collectedBasicInfo, setCollectedBasicInfo] = useState<BasicInfoType | undefined>(undefined)
  const [collectedAbstractInfo, setCollectedAbstractInfo] = useState<AbstractInfoType | undefined>( undefined)
  const [suggestPropertyAPIResponseMessage, setSuggestPropertyAPIResponseMessage] = useState<string | undefined>(undefined)
  const [suggestPropertyState, setSuggestPropertyState] = useState<boolean>(false)

  useEffect(() => {
    // ここにAPIコールを実行します。
    const fetchData = async () => {
      try {
        const response = await axios.get(START_CONVERSATION_API_URL)
        console.log(response.data) // レスポンスデータを確認
        const newChatHistory: Message[] = [response.data.firstMessage]
        setChatHistory(newChatHistory)
      } catch (error) {
        console.error("APIからのデータ取得中にエラーが発生しました", error)
        // エラーハンドリングをここで行う
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    console.log("BASIC INFO", collectedBasicInfo)
    console.log("ABSTRACT INFO", collectedAbstractInfo)
    if (
      chatHistory.length === BASIC_CONVERSATION_NUM + ABSTRACT_CONVERSATION_NUM &&
      collectedBasicInfo !== undefined &&
      collectedAbstractInfo !== undefined
    ) {
      // 物件情報を検索するエンドポイントにアクセスする実装
      const fetchPropertyList = async () => {
        const requestData: SuggestPropertyAPIType = {
          requestedBasicInfoObject: collectedBasicInfo,
          requestedAbstractInfoObject: collectedAbstractInfo,
        }
        try {
          const response = await axios.post(SUGGEST_PROPERTY_API_URL, requestData)
          if (response.status === 200 && response.data.propertyList !== null) {
            console.log('Get Property List', response.data.propertyList);
            console.log('Suggest State', suggestPropertyState);
            setPropertyList(response.data.propertyList);
            setSuggestPropertyAPIResponseMessage(response.data.responseText);
          } else if (response.status === 200 && response.data.responseText !== null){
            console.log('no properties', response.data.propertyList);
            const newChatHistory: Message[] = [...chatHistory, {
                id: chatHistory.length + 1,
                text: response.data.responseText,
                position: 'left',
                sender: 'AI'
            }]
            setChatHistory(newChatHistory);
          } else {
            console.log("Response Error", response)
          }
        } catch (error) {
          console.log("Error POST", error)
        }
      }
      fetchPropertyList()
    }
  }, [chatHistory, collectedAbstractInfo, collectedBasicInfo])

  useEffect(() => {
    if (propertyList !== undefined && collectedAbstractInfo !== undefined && collectedBasicInfo !== undefined && suggestPropertyState === false && suggestPropertyAPIResponseMessage !== undefined){
        console.log('Rendaring Result', propertyList)
        
        const newChatHistory: Message[] = [...chatHistory,{
            id: chatHistory.length + 1,
            text: suggestPropertyAPIResponseMessage,
            position: "left",
            sender: "AI"
        }]
        setChatHistory(newChatHistory);
        setSuggestPropertyState(true);
    }
  }, [propertyList, collectedBasicInfo, collectedAbstractInfo, suggestPropertyAPIResponseMessage]);

  //　チャットページで送信ボタンが押された時の処理
  const handleSendButton = async () => {
    if (inputMessage !== "") {
      const newChatHistory: Message[] = [
        ...chatHistory,
        {
          id: chatHistory.length + 1,
          text: inputMessage,
          position: "right",
          sender: "user",
        },
      ]
      // まずはローカルのステートを更新する
      setChatHistory(newChatHistory)
      setInputMessage("")
      try {
        if (chatHistory.length + 1 <= BASIC_CONVERSATION_NUM) {
          const requestData: CollectInfoAPIType = {
            chatHistory: newChatHistory,
          }
          const response = await axios.post(COLLECT_BASIC_INFO_API_URL, requestData)
          if (response.status === 200) {
            console.log(response.data)
            const addResponseChatHistory: Message[] = [
              ...newChatHistory,
              {
                id: newChatHistory.length + 1,
                text: response.data.responseText,
                position: "left",
                sender: "AI",
              },
            ]
            setChatHistory(addResponseChatHistory)
            console.log(addResponseChatHistory)
            setCollectedBasicInfo(response.data.collectedBasicInfoObject)
          } else {
            console.log("Response Error", response)
          }
        } else {
          const requestData: CollectInfoAPIType = {
            chatHistory: newChatHistory,
            requestedBasicInfoObject: collectedBasicInfo,
          }
          const response = await axios.post(COLLECT_ABSTRACT_INFO_API_URL, requestData)
          if (response.status === 200) {
            console.log(response.data)
            if (newChatHistory.length < BASIC_CONVERSATION_NUM + ABSTRACT_CONVERSATION_NUM){
                const addResponseChatHistory: Message[] = [
                        ...newChatHistory,
                        {
                        id: newChatHistory.length + 1,
                        text: response.data.responseText,
                        position: "left",
                        sender: "AI",
                        },
                    ]
                setChatHistory(addResponseChatHistory);
                console.log(addResponseChatHistory);
            }
            setCollectedAbstractInfo(response.data.collectedAbstractInfoObject);
          } else {
            console.log("Response Error", response)
          }
        }
      } catch (error) {
        console.log("Error POST", error)
      }
    } else {
      alert("メッセージを入力して！")
    }
  }

  return (
    <div style={{ height: "calc(100vh - 120px)" }}>
      <Header />
      <div
        style={{
          width: "97%",
          margin: "1rem auto",
          backgroundColor: "rgba(217,232,177,0.15)",
          paddingTop: "20px",
          paddingBottom: "20px",
          borderRadius: "20px",
          height: "100%",
        }}
      >
        <Chat
          messages={chatHistory}
          onSendButton={handleSendButton}
          inputMessage={inputMessage}
          onChangeInputMessage={setInputMessage}
        />
      </div>
    </div>
  )
}
export default AIChatPageView
