import React from "react"
import "./Header.css"

const Header = () => {
  return (
    <div className="header">
      <div className="header-title">AI不動産(仮)</div>
    </div>
  )
}

export default Header
