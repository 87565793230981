import React from 'react';
import logo from './logo.svg';
import './App.css';
import AIChatPageView from './views/AIChatPageView';

const App: React.FC = () => {
  
  return (
    <AIChatPageView/>
  );
};

export default App;

