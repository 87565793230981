import React, { useState } from "react"
import "./Chat.css"
import { IoSend } from "react-icons/io5";

// メッセージを表す型
export interface Message {
  id: number
  text: string
  position: "left" | "right"
  sender: "AI" | "user"
}

// Chatコンポーネントのプロップス型を定義
interface ChatProps {
  messages: Message[]
  onSendButton: () => void
  inputMessage: string
  onChangeInputMessage: (userInputMessage: string) => void
}

const Chat: React.FC<ChatProps> = (props) => {
  // テキストエリアの値が変更されたときに呼び出されるハンドラ
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChangeInputMessage(e.target.value)
  }

  return (
    <div id="chat">
      <div id="bms_messages">
        {props.messages.map((message) => (
          // メッセージの位置に基づいてクラスを動的に適用
          <div key={message.id} style={{display:"flex"}} className={`bms_message bms_${message.position}`}>
            <div className="bms_icon">{message.sender}</div>
            <div className="bms_message_box">
              <div className="bms_message_content">
                <div className="bms_message_text">
                {message.text.split('\n').filter(line => line.trim() !== '').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                </div>
              </div>
            </div>
            <div className="bms_clear"></div> {/*回り込みを解除*/}
          </div>
        ))}
      </div>

      {/* テキストボックス、送信ボタン */}
      <div id="bms_send">
        <textarea
          id="bms_send_message"
          value={props.inputMessage}
          onChange={handleMessageChange}
          placeholder="ここにメッセージを入力してください"
        ></textarea>
        <div id="bms_send_btn" onClick={props.onSendButton}>
          <IoSend size="2.5rem"/>
        </div>
      </div>
    </div>
  )
}

export default Chat
