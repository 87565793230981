export const BASIC_CONVERSATION_NUM = 4 // この回数以内の発言は物件情報の基本的情報を抽出する（デフォルト値6: ３往復の会話）

export const ABSTRACT_CONVERSATION_NUM = 2 // この回数の発言は物件情報の抽象的情報を抽出する（デフォルト値4: 2往復の会話）

// 各エンドポイントのURL
export const SUGGEST_PROPERTY_API_URL = `https://aichatappback.net/v1/api/suggest_property`

export const COLLECT_BASIC_INFO_API_URL = `https://aichatappback.net/v1/api/collect_basic_info`

export const COLLECT_ABSTRACT_INFO_API_URL = `https://aichatappback.net/v1/api/collect_abstract_info`

export const START_CONVERSATION_API_URL = `https://aichatappback.net/v1/api/start_conversation`
